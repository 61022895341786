(<template>
  <div class="projects-breadcrumbs">
    <div class="projects-breadcrumbs__wrapper">
      <span class="projects-breadcrumbs__arrow"></span>
      <router-link :to="projectDashboardLink"
                   class="projects-breadcrumbs__link">{{ $pgettext('translation', 'All Projects') }}</router-link>
    </div>
    <div class="projects-breadcrumbs__btn-cont">
      <button v-if="showPrintBtn"
              class="projects-breadcrumbs__btn projects-breadcrumbs__btn--print"
              @click="printAssignment">{{ $pgettext('translation', 'Print') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
      ]),
      serverProjectsLink() {
        return this.$makeRoute({
          name: 'ServerAllProjects',
          query: {
            available: 'new',
            accepted: 'active'
          }
        });
      },
      buyerProjectsLink() {
        return this.$makeRoute(
          {name: 'BuyerAllProjects',
           query: {
             view: 'active',
           }}
        );
      },
      projectDashboardLink() {
        const isServer = this.$store.state.UserInfoStore.mainUserInfo.role === 'server';
        return isServer ? this.serverProjectsLink : this.buyerProjectsLink;
      },
      showPrintBtn() {
        return this.userIsNotInterpreter;
      }
    },
    methods: {
      printAssignment() { window.print(); },
    }
  };
</script>

<style scoped>
.projects-breadcrumbs__btn {
  margin-left: 15px;
  padding-right: 25px;
  background-position: 100% 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.projects-breadcrumbs__btn--print {
  background-image: url(~@assets/imgs/undefined_imgs/printer-img.svg);
}

.projects-breadcrumbs {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 12px;
  font-size: 12px;
}

.projects-breadcrumbs__wrapper {
  display: flex;
}

.projects-breadcrumbs__arrow {
  display: inline-block;
  align-self: center;
  width: 10px;
  height: 13px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/arrows/big_arrow_to_left.svg);
  background-position: 50% 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-weight: bold;
}

.projects-breadcrumbs__link {
  font-size: 13px;
}

.projects-breadcrumbs__btn:focus,
.projects-breadcrumbs__link:focus {
  box-shadow: var(--default-focus-shadow);
  outline: none;
}

@media (max-width: 767px) {
  .projects-breadcrumbs {
    display: none;
  }
}
</style>
