// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/printer-img.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/arrows/big_arrow_to_left.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.projects-breadcrumbs__btn[data-v-2a5a07c7] {\n  margin-left: 15px;\n  padding-right: 25px;\n  background-position: 100% 50%;\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n}\n.projects-breadcrumbs__btn--print[data-v-2a5a07c7] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.projects-breadcrumbs[data-v-2a5a07c7] {\n  position: relative;\n  top: 0;\n  left: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n  padding-top: 3px;\n  padding-bottom: 12px;\n  font-size: 12px;\n}\n.projects-breadcrumbs__wrapper[data-v-2a5a07c7] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.projects-breadcrumbs__arrow[data-v-2a5a07c7] {\n  display: inline-block;\n  -ms-flex-item-align: center;\n      align-self: center;\n  width: 10px;\n  height: 13px;\n  margin-right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 50% 50%;\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n  font-weight: bold;\n}\n.projects-breadcrumbs__link[data-v-2a5a07c7] {\n  font-size: 13px;\n}\n.projects-breadcrumbs__btn[data-v-2a5a07c7]:focus,\n.projects-breadcrumbs__link[data-v-2a5a07c7]:focus {\n  -webkit-box-shadow: var(--default-focus-shadow);\n          box-shadow: var(--default-focus-shadow);\n  outline: none;\n}\n@media (max-width: 767px) {\n.projects-breadcrumbs[data-v-2a5a07c7] {\n    display: none;\n}\n}\n", ""]);
// Exports
module.exports = exports;
